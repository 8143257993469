import React from 'react';

import imgLogo from 'assets/img/cen_logo1_new.png';
import Image from 'components/Image/Image';

const Logo = ({ ...rest }) => (
	<Image img={imgLogo} alt='Cenegenics Logo' {...rest} />
);

export default Logo;
